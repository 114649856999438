import { useAuth } from '@/services/Auth'
import { useRef, useState } from 'react'
import LOCATION from '@/constants/Location'
import AsyncSelect from 'react-select/async'

function RegionSearch ({ setValue, placeHolder, region }) {
  const auth = useAuth()

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const [selectedRegion, setSelectedRegion] = useState(region ? {value: region.region_id, label: region.region_name} : null)

  const getRegions = (search) => {

    requests.locations && requests?.locations?.abort && requests.locations.abort()

    return new Promise((resolve, reject) => {
      requests.locations = auth.getRequest(LOCATION.AIRPORTS.API.path, { search })

      requests.locations.then(response => {
        resolve(response.data?.data?.map(_ => {
          return { value: _.region_id, label: _.region_name }
        }))
      })
        .catch(error => reject(error))
    })
  }

  return (
    <>
      <AsyncSelect
        className="w-100 react-select"
        isClearable={true}
        value={selectedRegion}
        onChange={(e) => {
          setSelectedRegion(e)
          setValue('region_id', e?.value)
        }}
        placeholder={placeHolder}
        defaultOptions={true}
        loadOptions={getRegions}/>
    </>
  )

}

export default RegionSearch