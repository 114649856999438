import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import LoadingCover from '@/shared/components/LoadingCover'
import { useAuth } from '@/services/Auth'
import OnPageNotification from '@/shared/components/OnPageNotification'
import CheckCircleIcon from '@/shared/components/Icons/CheckCircleIcon'
import LOCATION from '@/constants/Location'
import useGet from '@/hooks/useGet'
import TravelMaxPremium from '../Banners/TravelMaxPremium'
import StepControl from './JourneySteps/StepControl'
import moment from "moment";

function StartYourJourney () {
  const { t } = useTranslation()
  const auth = useAuth()
  const notification = auth.newNotification

  const { step } = useParams()

  const { data: holidayTypes, loading } = useGet(LOCATION.HOLIDAY_TYPES.API.path)

  const [travelMaxPremiumBanner, setTravelMaxPremiumBanner] = useState(false)
  const [continueWithDeal, setContinueWithDeal] = useState(false)
  const [dealNotificationReceived, setDealNotificationReceived] = useState(false)
  const [dealsFound, setDealsFound] = useState(false)
  const [tripRequest, setTripRequest] = useState(null)
  const [requestSent, setRequestSent] = useState(false)
  const [intervalValue, setIntervalValue] = useState(null)
  const [canceledSearch, setCanceledSearch] = useState(false)
  const [notificationTitle, setNotificationTitle] = useState(null)
  const [notificationDescription, setNotificationDescription] = useState(null)

  const showTravelMaxBanner = (value) => {

    setContinueWithDeal(false)
    setTravelMaxPremiumBanner(value)
  }
  const checkDeals = (notification) => {
    if( notification?.canceled ){
      setDealNotificationReceived(true)
      setCanceledSearch(true)
      setDealsFound(false)
      return  null;
    }
    return auth.getRequest(`${LOCATION.TRIPS.REQUEST_API.path}/${notification?.trip_request_id}`, {deals: 1})
      .then(response => {
        setTripRequest(response.data.data);

        if (response.data.data?.deals?.length !== 0) {
          setDealNotificationReceived(true)
          setDealsFound(true)
          clearInterval(intervalValue)
        } else {
          setDealNotificationReceived(true)
          setDealsFound(false)
        }
      })
  }
  const requestComplete = (request) => {
    setTripRequest(request)
    setRequestSent(true)

    //check deals manually encase websockets not working

    let dealChecks = 0

    setTimeout(() => {

      let interval = setInterval(() => {

        if (dealChecks >= 4) {
          checkSearchStatusManual(tripRequest?.id, true)
          clearInterval(intervalValue)
        } else {
          checkSearchStatusManual(tripRequest?.id, false)
        }
        dealChecks++

      }, 60000)

      setIntervalValue(interval)

    }, 480000)
  }

  const checkSearchStatusManual = (requestId, finish) => {
    auth.getRequest(`${LOCATION.TRIPS.REQUEST_API.path}/${requestId}`)
      .then(response => {
        if (response.data?.data?.search_completed || finish) {
          checkDeals()
        }
      })
  }

  useEffect(() => {
    if (notification && notification?.event_type === 'deal_request_response' && notification.tripRequest.search_completed === false) {
      setNotificationTitle(t(`pages.deals.notifications.progress.${notification.tripRequest.progress_status}.title`))
      setNotificationDescription(t(`pages.deals.notifications.progress.${notification.tripRequest.progress_status}.description`, {
        nearest_airports: notification?.tripRequest?.nearest_airports?.join(', ')
      }))
      return;
    }

    if (notification && notification?.event_type === 'deal_request_response') {
      auth.clearNotification()
      let request = checkDeals(notification)
      return () => request?.abort && request.abort()
    }
  }, [notification])

  useEffect(() => {
    let requestData = localStorage.getItem('request_data')
    if (requestData) {
      localStorage.setItem('trip_values', requestData)
      setContinueWithDeal(true)
    }
  }, [])

  if (loading) return <LoadingCover/>

  if (travelMaxPremiumBanner && !continueWithDeal) {
    return <TravelMaxPremium setContinueWithDeal={setContinueWithDeal}/>
  }

  if (dealNotificationReceived && dealsFound && requestSent) {

    let link = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/results/${tripRequest?.id}`
    if (auth.user?.admin) {
      link = `${LOCATION.DEALS.LIST.path}/search/${tripRequest?.id}`
    }
    return (
      <OnPageNotification
        notificationTitle={t('pages.deals.notifications.deals_found.title')}
        notificationDescription={t('pages.deals.notifications.deals_found.body')}
        notificationLinkText={t('common.dialogs.actions.take_me_there')}
        notificationLink={link}
        notificationIcon={<CheckCircleIcon/>}
      />
    )
  }

  if (dealNotificationReceived && !dealsFound && requestSent) {
    return (
      canceledSearch ?
      <OnPageNotification
        notificationTitle={t('pages.deals.notifications.no_deal_found_on_request_limit_reached.title')}
        notificationDescription={t('pages.deals.notifications.no_deal_found_on_request_limit_reached.body')}
        notificationLinkText={t('common.dialogs.actions.okay')}
        notificationAction={()=> window.location.reload()}
        notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
      />
        :
      <OnPageNotification
        notificationTitle={t('pages.deals.notifications.no_deal_found_on_request.title')}
        notificationDescription={t(`pages.deals.notifications.no_deal_found_on_request.reasons.${tripRequest.progress_status}`)}
        notificationLinkText={t('common.dialogs.actions.edit_my_search')}
        notificationAction={()=> window.location.reload()}
        notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
      />
    )
  }

  if (requestSent) {
    let estimatedTime = moment(tripRequest?.estimated_complete_time).fromNow()

    if(estimatedTime.includes('ago') ){
      estimatedTime = ' in a few seconds'
    }
    let translationKey = 'pages.trips.notifications.request_sent.body_normal';
    let duration  =  moment.duration(moment(tripRequest?.estimated_complete_time).diff(moment()));
    if( duration.asMinutes() >= 10 ){
      translationKey = 'pages.trips.notifications.request_sent.body_high_demand';
    }

    return (
      <OnPageNotification
        showProgressSpinner={true}
        notificationTitle={notificationTitle ?? t('pages.trips.notifications.request_sent.title')}
        notificationDescription={notificationDescription ?? t(translationKey, {estimated:estimatedTime})}
        notificationLinkText={auth.user?.admin ? t('pages.trips.notifications.request_sent.link_text') : t('common.buttons.go_home')}
        notificationLink={LOCATION.ROOT.path}
        notificationIcon={<CheckCircleIcon/>}
      />
    )
  }

  return (
    <StepControl
      step={step}
      holidayTypes={holidayTypes}
      showTravelMaxBanner={showTravelMaxBanner}
      continueWithDeal={continueWithDeal}
      requestComplete={requestComplete}
    />
  )
}

export default StartYourJourney